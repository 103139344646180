<template>
    <main>
        <loading
            :active="isLoading"
            :can-cancel="false"
            :on-cancel="onCancel"
            :is-full-page="fullPage"
        />
        <HeadShop :showButtonPhotoProfile="false" />

        <section class="section pt-0 my-status">
            <div class="container">
                <p class="breadcrumb">
                    <a href="/">
                        <span class="icon-home-outline"></span>
                    </a> /
                    <span>Registro usuario</span>
                </p>
                <h2>
                    <a href="#" @click="router.go(-1)">
                        <img src="/images/arrowleft.svg" alt="Club de Sastreria Barrington" />
                    </a> Registro usuario
                </h2>
                <form
                    action
                    class="form container container--xs"
                    @submit.prevent="onSubmit"
                    v-if="!showSuccessRegisterUser"
                >
                    <input type="hidden" v-model="formRegisterUser.username" />
                    <div class="form__control">
                        <label class="form__label">Seleccionar tipo de usuario :</label>
                        <select
                            class="form__field form__field--select"
                            v-model="formRegisterUser.type_user"
                        >
                            <option :value="null">Seleccione un tipo de usuario</option>
                            <option
                                :value="tu.id"
                                v-for="(tu, k) in typeUser"
                                :key="k"
                            >{{ tu.name }}</option>
                        </select>
                        <p
                            class="form__alert"
                            v-if="messageError.errors.type_user"
                        >{{ messageError.errors.type_user[0] }}</p>
                    </div>
                    <div class="form__control">
                        <label class="form__label">Nombres :</label>
                        <input
                            type="text"
                            class="form__field"
                            v-model="formRegisterUser.first_name"
                            placeholder="Nombres completos del usuario"
                        />
                        <p
                            class="form__alert"
                            v-if="messageError.errors.first_name"
                        >{{ messageError.errors.first_name[0] }}</p>
                    </div>

                    <div class="form__control">
                        <label class="form__label">Apellidos :</label>
                        <input
                            type="text"
                            class="form__field"
                            v-model="formRegisterUser.last_name"
                            placeholder="Apellidos completos del usuario"
                        />
                        <p
                            class="form__alert"
                            v-if="messageError.errors.last_name"
                        >{{ messageError.errors.last_name[0] }}</p>
                    </div>
                    <div class="form__control">
                        <label class="form__label">DNI :</label>
                        <input
                            type="text"
                            class="form__field"
                            v-model="formRegisterUser.document_code"
                            placeholder
                        />
                        <p
                            class="form__alert"
                            v-if="messageError.errors.document_code"
                        >{{ messageError.errors.document_code[0] }}</p>
                    </div>
                    <div class="form__control">
                        <label class="form__label">¿Por cuál medio desea registrarse?</label>
                    </div>
                    
                    
                    
                    <label class="radio-inline">
                        <input
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineRadio1"
                            v-model="selectTypeRegister"
                            value="email"
                        /> Con correo electrónico
                    </label>
                    <label class="radio-inline">
                        <input
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineRadio2"
                            v-model="selectTypeRegister"
                            value="movil"
                        /> Con número de celular
                    </label>
                    
                    <div class="form__control" v-if="selectTypeRegister=='email'">
                        <label class="form__label form__label--error">Email :</label>
                        <input
                            type="email"
                            class="form__field"
                            v-model="formRegisterUser.email"
                            placeholder="usuarioEmail@email.com"
                        />
                        <p
                            class="form__alert"
                            v-if="messageError.errors.email"
                        >{{ messageError.errors.email[0] }}</p>
                    </div>
                    <!-- <div class="form__control">
                        <label for>
                            No tengo e-mail, solo numero de teléfono
                            <input
                                type="checkbox"
                                v-model="formRegisterUser.only_have_phone_movil"
                            />
                        </label>
                    </div> -->
                            <input
                                type="hidden"
                                v-model="formRegisterUser.only_have_phone_movil"
                            />
                    <div class="form__control" v-if="selectTypeRegister=='movil'">                        
                        <label class="form__label form__label--required form__label--error">
                            Móvil:                            
                        </label>
                        <div class="form__control__row">
                            <div>
                                <select class="form__field form__field--select">
                                    <option value="51">Perú (51+)</option>
                                    <option value="54">Argentina (54+)</option>
                                    <option value="591">Bolivia (591+)</option>
                                    <option value="56">Chile (56+)</option>
                                </select>
                            </div>
                            <div>
                                <input
                                    type="tel"
                                    v-model="formRegisterUser.phone_movil"
                                    class="form__field"
                                    placeholder
                                />
                            </div>
                        </div>
                        <p
                            class="form__alert"
                            v-if="messageError.errors.phone_movil"
                        >{{ messageError.errors.phone_movil[0] }}</p>
                    </div>

                    <button class="cta">Enviar registro de usuario</button>
                </form>

                <!-- <p class="form__success">
                    <span>
                        Se te enviará un mensaje a tu correo electrónico cuando se apruebe tu registro
                    </span>
                </p>-->

                <p class="form__success" v-if="showSuccessRegisterUser">
                    <template
                        v-if="formRegisterUser.only_have_phone_movil"
                    >Se te enviará un mensaje a tu nro {{ formRegisterUser.phone_movil }} cuando se apruebe tu registro.</template>
                    <template
                        v-else
                    >Se te enviará un mensaje a tu correo electrónico {{ formRegisterUser.email }} cuando se apruebe tu registro</template>
                </p>
            </div>
        </section>
    </main>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { ref } from "vue";
import API from "@/api";
import router from "@/router";
import store from "@/store";
import HeadShop from "@/views/private/shop/components/HeadShop";

export default {
    name: "RegisterUser",
    components: {
        HeadShop,
        Loading
    },
    setup() {
        var isLoading = ref(false);
        const fullPage = true;

        const dataShop = ref({
            name: "",
            country: "",
            department: "",
            address: ""
        });
        const formRegisterUser = ref({
            type_user: null,
            first_name: "",
            last_name: "",
            document_code: "",
            email: "",
            username: "demo1",
            phone_movil: "",
            only_have_phone_movil: false,
            shop_that_registered: store.state.dataToken.shop //tienda que lo registro
        });
        const typeUser = ref([]);
        const messageError = ref({ errors: {} });
        const showSuccessRegisterUser = ref(Boolean);
        const selectTypeRegister = ref("email")

        showSuccessRegisterUser.value = false;

        const getDataShop = () => ({
            name: localStorage.getItem("name"),
            country: localStorage.getItem("country"),
            department: localStorage.getItem("department"),
            address: localStorage.getItem("address")
        });

        dataShop.value = getDataShop();

        const getTypeUser = async () => {
            await API.get("/api/type-user")
                .then(response => {
                    typeUser.value = response.data;
                })
                .catch(error => {
                    console.log(error.response.data);
                });
        };

        const onSubmit = () => {
            isLoading.value = true;
            //debugger
            if (selectTypeRegister.value == 'movil'){
                formRegisterUser.value.only_have_phone_movil = true
            }
                
            API.post("/api/user", formRegisterUser.value)
                .then(response => {
                    isLoading.value = false;

                    //showSuccessRegisterUser.value = true;
                    let payload = getPayloadRegisterUserSuccess(
                        formRegisterUser.value
                    );
                    store.commit("setRegisterUserSuccess", payload);
                    return router.push({ name: "RegisterUserSuccess" });
                })
                .catch(error => {
                    isLoading.value = false;
                    console.log(error.response.data, "zsssssssssssssssssss");
                    messageError.value = error.response.data;
                });
        };

        const getPayloadRegisterUserSuccess = payload => {
            //debugger
            /*Actualiza el state para guardar los datos del usuario y mostrarselos cuando termine la solicitud
            de registro */
            if (payload.only_have_phone_movil) {
                return {
                    ...store.state.registerUserSuccess,
                    ...{
                        phone_movil: payload.phone_movil,
                        only_have_phone_movil: true
                    }
                };
            } else {
                return {
                    ...store.state.registerUserSuccess,
                    ...{ email: payload.email, only_have_phone_movil: false }
                };
            }
        };

        return {
            store,
            isLoading,
            fullPage,
            dataShop,
            formRegisterUser,
            typeUser,
            getTypeUser,
            onSubmit,
            messageError,
            showSuccessRegisterUser,
            selectTypeRegister,
        };
    },
    mounted() {
        this.getTypeUser();
    }
};
</script>